import React from "react"
import Title from "../Global/Title"

export default function PackageInfo() {
  return (
    <section id="about-gh">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-8 mx-auto text-center">
            <Title title="Service Scope" />
            <div className="separator" />
            <p className="mb-5">
              WordPress is the most widely adopted and popular way to build a
              website. A lot of websites that you visit on a daily basis are
              actually powered by WordPress. Because of its popularity, it
              becomes a usual target for hackers, exploiting various security
              leaks of this technology. In view of the increasingly vigorous
              hacking activities on websites, GIANTHOUSE offers professional
              WordPress security services to our clients in need. Our security
              solution ranges from basic implementation of essential security
              measures to curing hacked Wordpress websites.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
