import React from "react"
import Title from "../Global/Title"
import TableCard from "./TableCard"
import "./table.css"

const PACKAGES = [
  {
    title: "Essential",
    subtitle: "(plugin supported)",
    features: [
      "Install a WordPress Backup Solution",
      "Install WordPress Security Plugin (free version)",
      "Enable Web Application Firewall (WAF)",
      "Enforce SSL/HTTPS",
      "Keep everything up to date (core/theme/plugins)*",
    ],
    footer: {
      title: "*Compatibility issues resolution",
      items: ["Free if website is developed by Gianthouse"],
    },
    action: "Start Now",
  },
  {
    title: "Professional",
    subtitle: "",
    features: [
      "Change the Default “admin” username",
      "Disable File Editing",
      "Disable PHP File Execution",
      "Limit Login Attempts",
      "Add Two Factor Authentication",
      "Install a WordPress Backup Solution",
      "WP admin URL alteration",
      "Disable Directory Indexing and Browsing",
      "Disable XML-RPC in WordPress",
      "Automatically log out Idle Users",
      "Add Security Questions to WordPress Login",
      "Scanning WordPress for Malware and Vulnerabilities",
      "Covers everything in Essential package",
    ],
    footer: {},
    action: "Select",
  },
  {
    title: "Cure",
    subtitle: "(already infected)",
    features: [
      "Fixing a Hacked WordPress Site",
      "Covers everything in Essential and Professional packages",
    ],
    footer: {},
    action: "Contact Us",
  },
]

export default function PackageSection({ img }) {
  return (
    <section className="white-container">
      <div className="container">
        <div className="mx-auto text-center">
          <Title title="Security Packages" />
          <div className="separator" />
          <p>From essential to cure</p>

          <div className="package-table">
            {PACKAGES.map((pac) => (
              <TableCard key={pac.name} {...pac} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
