import React from "react"
import { Link } from "gatsby"
import { FaCheckCircle } from "react-icons/fa"
import "./table.css"

export default function TableCard({
  title,
  subtitle,
  features,
  footer,
  action,
}) {
  return (
    <div className="table-card-container">
      <div
        className="table-card-border"
        id={`package-${title.toLowerCase()}`}
      />

      <div className="table-card">
        <div className="table-card-content">
          <div className="table-card-heading">
            <h5 className="card-heading-title">{title}</h5>
            {!!subtitle && <p className="card-heading-subtitle">{subtitle}</p>}
          </div>

          <ul>
            {features.map((feature) => (
              <li key={feature}>
                <span>
                  <FaCheckCircle color="rgb(98, 223, 181)" />
                </span>
                {feature}
              </li>
            ))}
          </ul>

          <div className="table-action-container">
            <Link to="/contact">
              <button className="btn default-btn text-uppercase btn-round">
                {action}
              </button>
            </Link>
          </div>

          {footer.items && (
            <div className="table-card-footer">
              {footer.title}
              <br />
              {footer.items.map((item) => (
                <span key={item}>{item}</span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
