import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSection from "../components/Global/TopBanner"
import TableSection from "../components/Wordpress/TableSection"
import PackageInfo from "../components/Wordpress/PackageInfo"

const WordpressPage = ({ data }) => (
  <Layout>
    <SEO title="WP security packages" keywords={[`Wordpress`, `security`]} />
    <BannerSection
      img={data.img.childImageSharp.fluid}
      title="WordPress Security"
    />
    <PackageInfo />
    <TableSection />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "wp-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default WordpressPage
